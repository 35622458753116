import React, {useEffect, useState} from 'react';

import AppCard from '@crema/components/AppCard';
import AppRowContainer from '@crema/components/AppRowContainer';
import {Col} from 'antd';
import {useGetDataApi} from '@crema/hooks/APIHooks';
import AppLoader from '@crema/components/AppLoader';
import AppAnimate from '@crema/components/AppAnimate';

// WelcomeCard
import WelcomeCard from '../../../components/dashboard/WelcomeCard';
import SalesStatsItemCard from '../../../components/dashboard/SalesStatsItemCard';
import SalesEnquiryBookingChart from '../../../components/dashboard/SalesEnquiryBookingChart';
import SalesTodaysPaymentCard from '../../../components/dashboard/SalesTodaysPaymentCard';
import SPMonthlyStatsCard from '../../../components/dashboard/SPMonthlyStatsCard';
import axios from 'axios';
import dayjs from 'dayjs';
import './index.scss';
import {
  BACKEND_API_ENDPOINT,
  BACKEND_S3_ENDPOINT,
} from '../../../app/constants';

const DashboardPage = () => {
  const salesDashboardUrl = `${BACKEND_API_ENDPOINT}sales/get-sales-dashboard-stats`;

  const [{apiData: analyticsData, loading}] = useGetDataApi(
    '/dashboard/analytics',
  );

  const [followUpTotalCount, setFollowUpTotalCount] = useState(0);
  const [enquiryTotalCount, setEnquiryTotalCount] = useState(0);
  const [bookingTotalCount, setBookingTotalCount] = useState(0);
  const [todaysTotalAmount, setTodaysTotalAmount] = useState(0);

  const [bookingData, setBookingData] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);

  const [chartStats, setChartStats] = useState([]);
  const [todaysPayment, setTodaysPayment] = useState([]);

  const [salesPersonsStat, setSalesPersonsStat] = useState([]);

  const getDashboardData = async () => {
    const token = localStorage.getItem('token');

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios
      .get(salesDashboardUrl)
      .then((res) => {
        // setDashboardData(res.data);
        setFollowUpTotalCount(res.data.stats.follow_up_total_count);
        setEnquiryTotalCount(res.data.stats.enquiry_total_count);
        setBookingTotalCount(res.data.stats.booking_total_count);
        setTodaysTotalAmount(res.data.stats.todays_total_amount);

        setBookingData(res.data.stats.booking_stats);
        setEnquiryData(res.data.stats.enquiry_stats);

        setChartStats(res.data.stats.chart_stats);
        setTodaysPayment(res.data.stats.todays_payment);

        setSalesPersonsStat(res.data.stats.sales_persons_stats);
      })
      .catch((err) => {
        console.log(err);
        setFollowUpTotalCount(0);
        setEnquiryTotalCount(0);
        setBookingTotalCount(0);
        setTodaysTotalAmount(0);

        setBookingData([]);
        setEnquiryData([]);

        setChartStats([]);
        setTodaysPayment([]);

        setSalesPersonsStat([]);
      });
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  const [{apiData: crmData, loading2}] = useGetDataApi('/dashboard/crm');

  const metricsData = {
    metricsStats: [
      {
        id: 1,
        title: 'follow-up',
        done: '0',
        pending: '0',
        total: followUpTotalCount,
        badgeColor: 'rgba(249, 145, 28, 0.2)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/follow_up.png',
        link: '/sales/follow-up',
      },
      {
        id: 2,
        title: 'Handover',
        done: '0',
        pending: '0',
        total: '0',
        badgeColor: 'rgba(255, 230, 230, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/handover.png',
        link: '',
      },
      {
        id: 3,
        title: 'CEO asgmt',
        done: '0',
        pending: '0',
        total: '0',
        badgeColor: 'rgba(216, 222, 255, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/assigned_task.png',
        link: '/',
      },
      {
        id: 4,
        title: 'Enquiry',
        done: '0',
        pending: '0',
        total: enquiryTotalCount,
        badgeColor: 'rgba(204, 239, 241, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/enquiry.png',
        link: '/sales/view-all-enquiry',
      },
      {
        id: 5,
        title: 'Booking',
        done: '0',
        pending: '0',
        total: bookingTotalCount,
        badgeColor: 'rgba(216, 222, 255, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/booking.png',
        link: '/sales/view-all-booking',
      },
      {
        id: 6,
        title: 'Add task',
        done: '0',
        pending: '0',
        total: '0',
        badgeColor: 'rgba(255, 230, 230, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/additional_task.png',
        link: '/',
      },
      {
        id: 7,
        title: 'Feedback ',
        done: '0',
        pending: '0',
        total: '0',
        badgeColor: 'rgba(249, 145, 28, 0.2)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/feedback.png',
        link: '/',
      },
      {
        id: 8,
        title: 'Payment  ',
        done: '0',
        pending: '0',
        total: todaysTotalAmount,
        badgeColor: 'rgba(204, 239, 241, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/call_for_payment.png',
        link: '/sales/view-all-received-payment',
      },
      {
        id: 9,
        title: 'Mgr asgmt',
        done: '0',
        pending: '0',
        total: '0',
        badgeColor: 'rgba(216, 222, 255, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/manager_task.png',
        link: '/',
      },
    ],
  };

  //   const chartData = {
  //     chartStats: [
  //       {name: 'Jan', Booking: 150, Enquiries: 270},
  //       {name: 'Feb', Booking: 250, Enquiries: 200},
  //       {name: 'Mar', Booking: 180, Enquiries: 280},
  //       {name: 'Apr', Booking: 278, Enquiries: 250},
  //       {name: 'May', Booking: 250, Enquiries: 300},
  //       {name: 'Jun', Booking: 350, Enquiries: 250},
  //       {name: 'Jul', Booking: 280, Enquiries: 300},
  //       {name: 'Aug', Booking: 340, Enquiries: 240},
  //       {name: 'Sep', Booking: 280, Enquiries: 300},
  //       {name: 'Oct', Booking: 400, Enquiries: 270},
  //       {name: 'Nov', Booking: 250, Enquiries: 240},
  //       {name: 'Dec', Booking: 400, Enquiries: 270},
  //     ],
  //   };

  const chartData = {
    chartStats: chartStats,
  };

  return loading ? (
    <AppLoader />
  ) : (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <AppRowContainer
        interval={120}
        ease={'easeInSine'}
        className='page_dashboard'
      >
        <Col xs={24} lg={24} xl={24} className='mb-0' key={'a'}>
          <AppRowContainer interval={120}>
            <Col lg={24}>
              <AppRowContainer>
                {metricsData.metricsStats.map((item, index) => (
                  <Col xs={12} sm={12} md={8} lg={8} key={index}>
                    <SalesStatsItemCard stats={item} />
                  </Col>
                ))}
              </AppRowContainer>
            </Col>
            {/* <Col lg={6}>
              <WelcomeCard data={analyticsData.welcomeCardCustom} />
            </Col> */}
          </AppRowContainer>
        </Col>

        <Col xs={24} lg={10} xxl={10} key={'f'}>
          <SalesEnquiryBookingChart data={chartData.chartStats} />
        </Col>

        <Col xs={24} lg={6} key={'e'}>
          <SalesTodaysPaymentCard todaysPayment={todaysPayment} />
        </Col>
        <Col xs={24} lg={6} key={'g'}>
          <SPMonthlyStatsCard salesPersonsStat={salesPersonsStat} />
        </Col>
      </AppRowContainer>
    </AppAnimate>
  );
};

export default DashboardPage;
