import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
// import Account from '../../../modules/account/MyProfile';
// import DashboardPage from '../../../modules/demo/dashboard';

const PageDashboard = React.lazy(() =>
  import('../../../modules/ticketAgentPages/dashboard'),
);

const PageGroupSchedule = React.lazy(() =>
  import('../../../modules/ticketAgentPages/group_schedule'),
);

const PageViewAllPassenger = React.lazy(() =>
  import('../../../modules/ticketAgentPages/view_passenger'),
);

const PageAgentAddSeatsBlock = React.lazy(() =>
  import('../../../modules/ticketAgentPages/add_seats_block'),
);

const PageAgentManageVendors = React.lazy(() =>
  import('../../../modules/ticketAgentPages/manage_vendors'),
);

export const ticketAgentSidebarPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/ticket-agent/dashboard',
    element: <PageDashboard />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/ticket-agent/group-schedule',
    element: <PageGroupSchedule />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/ticket-agent/view-all-passenger',
    element: <PageViewAllPassenger />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/ticket-agent/add-seats-block',
    element: <PageAgentAddSeatsBlock />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/ticket-agent/manage-ticket-vendors',
    element: <PageAgentManageVendors />,
  },
];
