import React from 'react';
import {
  BiBook,
  BiCalendar,
  BiDollar,
  BiPhoneIncoming,
  BiBriefcase,
  BiUser,
  BiGroup,
  BiHelpCircle,
  BiPhoneCall,
  BiBookOpen,
  BiListUl,
  BiLayer,
  BiPhoneOutgoing,
  BiTaxi,
  BiSolidMap,
  BiCategory,
  BiBookContent,
  BiRupee,
  BiPaperPlane,
} from 'react-icons/bi';
import {
  MdManageHistory,
  MdConnectingAirports,
  MdAirlineSeatReclineNormal,
  MdOutlineEmojiPeople,
  MdAirplanemodeActive,
} from 'react-icons/md';
import {BsFillPeopleFill} from 'react-icons/bs';

const routeConfigAdmin = [
  {
    id: 'app',
    title: 'Admin Menu',
    messageId: 'sidebar.admin',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.dashboard',
        type: 'item',
        icon: <BiLayer />,
        path: '/admin/dashboard',
      },
      {
        id: 'schedule-01',
        title: 'Booking Schedule',
        messageId: 'sidebar.page.viewAllBookingSchedule',
        type: 'item',
        icon: <BiBook />,
        path: '/admin/view-all-booking-schedule',
      },
      {
        id: 'page-02',
        title: 'Page 02',
        messageId: 'sidebar.page.viewAllEnquiry',
        type: 'item',
        icon: <BiListUl />,
        path: '/admin/view-all-enquiry',
      },
      {
        id: 'page-03',
        title: 'Page 03',
        messageId: 'sidebar.page.viewAllCustomEnquiry',
        type: 'item',
        icon: <BiListUl />,
        path: '/admin/view-all-custom-enquiry',
      },
      {
        id: 'follow-up-1',
        title: 'follow-up 1',
        messageId: 'sidebar.page.followUp',
        type: 'item',
        icon: <BiPhoneOutgoing />,
        path: '/admin/follow-up',
      },
      {
        id: 'page-04',
        title: 'Page 04',
        messageId: 'sidebar.page.viewAllBooking',
        type: 'item',
        icon: <BiListUl />,
        path: '/admin/view-all-booking',
      },
      {
        id: 'page-05',
        title: 'Page 05',
        messageId: 'sidebar.page.viewAllCustomBooking',
        type: 'item',
        icon: <BiListUl />,
        path: '/admin/view-all-custom-booking',
      },
      {
        id: 'page-06',
        title: 'Page 06',
        messageId: 'sidebar.page.all_received_payment',
        type: 'item',
        icon: <BiRupee />,
        path: '/admin/view-all-received-payment',
      },
      {
        id: 'page-manage-nav',
        title: 'Manage Navigation',
        messageId: 'sidebar.page.manage',
        type: 'item',
        icon: <MdManageHistory />,
        path: '/admin/manage-navigation',
      },
      // {
      //   id: 'mange-drop-down',
      //   title: 'Manage',
      //   messageId: 'sidebar.page.manage',
      //   type: 'collapse',
      //   icon: <MdManageHistory />,
      //   children: [
      //     {
      //       id: 'manage-1',
      //       title: 'package type',
      //       messageId: 'sidebar.page.package_type',
      //       icon: <BiCategory />,
      //       path: '/admin/manage/package-type',
      //     },
      //     {
      //       id: 'manage-2',
      //       title: 'Manage Package',
      //       messageId: 'sidebar.page.package',
      //       icon: <BiBookContent />,
      //       path: '/admin/manage/manage-package',
      //     },
      //     {
      //       id: 'manage-3',
      //       title: 'Manage Package Date',
      //       messageId: 'sidebar.page.addDate',
      //       icon: <BiCalendar />,
      //       path: '/admin/add-date',
      //     },
      //     {
      //       id: 'manage-4',
      //       title: 'Communication Source Category',
      //       messageId: 'sidebar.page.communication_source_category',
      //       icon: <BiSolidMap />,
      //       path: '/admin/manage/communication-source-category',
      //     },
      //     {
      //       id: 'manage-5',
      //       title: 'Reference Source Category',
      //       messageId: 'sidebar.page.reference_source_category',
      //       icon: <MdOutlineEmojiPeople />,
      //       path: '/admin/manage/reference-source-category',
      //     },
      //     {
      //       id: 'manage-6',
      //       title: 'Manage Airport',
      //       messageId: 'sidebar.page.manage_airport',
      //       icon: <MdConnectingAirports />,
      //       path: '/admin/manage/manage-airport',
      //     },
      //     {
      //       id: 'manage-7',
      //       title: 'Flight Class',
      //       messageId: 'sidebar.page.manage_flight_class',
      //       icon: <MdAirlineSeatReclineNormal />,
      //       path: '/admin/manage/manage-flight-class',
      //     },
      //     {
      //       id: 'manage-8',
      //       title: 'Airlines',
      //       messageId: 'sidebar.page.airlines',
      //       icon: <BiPaperPlane />,
      //       path: '/admin/manage/manage-airlines',
      //     },
      //     {
      //       id: 'manage-9',
      //       title: 'Vehicle Type',
      //       messageId: 'sidebar.page.manage_vehicle_type',
      //       icon: <BiTaxi />,
      //       path: '/admin/manage/manage-vehicle-type',
      //     },
      //     {
      //       id: 'manage-10',
      //       title: 'Manage Users',
      //       messageId: 'sidebar.page.manage_users',
      //       icon: <BsFillPeopleFill />,
      //       path: '/admin/manage/manage-users',
      //     },
      //   ],
      // },
    ],
  },
];
export default routeConfigAdmin;
