import React from 'react';
import AppCard from '@crema/components/AppCard';
import {Link} from 'react-router-dom';

import {Avatar, Typography} from 'antd';
import PropTypes from 'prop-types';
import {getAssetsUrl} from '@crema/helpers/UrlHelper';
import {
  StyledFlexContainer,
  StyledItemContainer,
  StyledTag,
  StyledTitleWrapper,
} from './index.styled';
import './index.scss';

const SalesStatsItemCard = ({stats}) => {
  return (
    <AppCard className='card-hover'>
      <StyledFlexContainer>
        <div style={{marginRight: 16}}>
          <div
            className='custom_avatar'
            style={{
              width: 46,
              height: 46,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 32,
              backgroundColor: stats.badgeColor,
              padding: 2,
            }}
          >
            <img src={getAssetsUrl(stats.icon)} alt='' />
          </div>
        </div>
        <StyledItemContainer>
          <div style={{overflow: 'hidden'}} className='card_info_wrapper'>
            <div className='card_center_row'>
              <Link to={stats.link}>
                <div className='card_title'> {stats.title}</div>
                <div className='count_wrapper'>
                  {/* <div className='done'>Done {stats.done}</div> */}
                  {/* <div className='pending'>Pending {stats.pending}</div> */}
                </div>
              </Link>
            </div>

            <div className='total_count'>{stats.total}</div>
          </div>

          {/* <StyledTag
            style={{
              backgroundColor: stats.bgcolor,
              color: stats.badgeColor,
            }}
          >
            {stats.total}
          </StyledTag> */}
        </StyledItemContainer>
      </StyledFlexContainer>
    </AppCard>
  );
};

export default SalesStatsItemCard;

SalesStatsItemCard.propTypes = {
  stats: PropTypes.object,
};
