import React from 'react';
import {
  BiBook,
  BiCalendar,
  BiDollar,
  BiPhoneIncoming,
  BiBriefcase,
  BiUser,
  BiGroup,
  BiHelpCircle,
  BiPhoneCall,
  BiBookOpen,
  BiListUl,
  BiLayer,
} from 'react-icons/bi';
import {MdCalendarMonth, MdPeopleAlt, MdSpaceDashboard} from 'react-icons/md';

const routeConfigTicketAgent = [
  {
    id: 'app',
    title: 'Ticket Executive Menu',
    messageId: 'sidebar.ticketExecutive',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.dashboard',
        type: 'item',
        icon: <BiLayer />,
        path: '/ticket-agent/dashboard',
      },
      {
        id: 'group_schedule',
        title: 'Group Schedule',
        messageId: 'sidebar.page.group_schedule',
        type: 'item',
        icon: <MdCalendarMonth />,
        path: '/ticket-agent/group-schedule',
      },
      {
        id: 'page-02',
        title: 'add seats block	',
        messageId: 'sidebar.page.add_seats_block',
        type: 'item',
        icon: <MdSpaceDashboard />,
        path: '/ticket-agent/add-seats-block',
      },
      {
        id: 'manage_ticket_agent_vendors',
        title: 'Manage Ticket Vendors',
        messageId: 'sidebar.page.manage_ticket_vendors',
        type: 'item',
        icon: <MdPeopleAlt />,
        path: '/ticket-agent/manage-ticket-vendors',
      },
    ],
  },
];
export default routeConfigTicketAgent;
