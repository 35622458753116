import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';

import {authRouteConfig} from './AuthRoutes';
import Error403 from '../../modules/errorPages/Error403';
import {errorPagesConfigs} from './ErrorPagesRoutes';
import {dashboardConfig} from './DashboardsRoutes';
import {extraPagesConfigs} from './ExtraPagesRoutes';
import {ecommerceConfig} from './EcommerceRoutes';
import {userListConfig} from './UserListRoutes';
import {userPagesConfig} from './UserPagesRoutes';
import {thirdPartyConfigs} from './ThirdPartyRoutes';
import {appsConfig} from './AppsRoutes';
import {accountPagesConfigs} from './AccountRoutes';
import {invoiceConfig} from './InvoiceRoutes';
import {componentsConfigs} from './Components';
import {sidebarPagesConfigs} from './SidebarRoutes';
import {adminSidebarPagesConfigs} from './AdminSidebarRoutes';
import {accountantSidebarPagesConfigs} from './AccountantSidebarRoutes';
import {ticketAgentSidebarPagesConfigs} from './TicketAgentSidebarRoutes';
import {visaAgentSidebarPagesConfigs} from './VisaAgentSidebarRoutes';
import {useAuthUser} from '../../../hooks/AuthHooks';

export const authorizedStructure = (loginUrl) => {
  const {user, isAuthenticated} = useAuthUser();

  let customRoutes;

  // Check the user's role and set the customRoutes accordingly
  console.log('user.roleId', user?.roleId);
  if (user?.roleId == 1 || user?.roleId == 2) {
    // Super Admin & Admin
    customRoutes = adminSidebarPagesConfigs;
  } else if (user?.roleId == 3 || user?.roleId == 4) {
    // Sales Executive & Sales Trainee
    customRoutes = sidebarPagesConfigs;
  } else if (user?.roleId == 5) {
    //Accountant
    customRoutes = accountantSidebarPagesConfigs;
  } else if (user?.roleId == 6) {
    //Visa Executive
    customRoutes = visaAgentSidebarPagesConfigs;
  } else if (user?.roleId == 7) {
    //Ticket Executive
    customRoutes = ticketAgentSidebarPagesConfigs;
  } else if (user?.roleId == 8) {
    //Operational Head
  } else if (user?.roleId == 9) {
    //Manager
  } else if (user?.roleId == 10) {
    //Warehouse Manager (only for Arabia Originals) India Manager, Makkah Manager, Madinah Manager
  } else {
    customRoutes = []; // Default to an empty array if the user's role doesn't match any known roles
  }

  return {
    fallbackPath: loginUrl,
    unAuthorizedComponent: <Error403 />,
    routes: [
      ...dashboardConfig,
      ...accountPagesConfigs,
      ...appsConfig,
      ...thirdPartyConfigs,
      ...extraPagesConfigs,
      ...ecommerceConfig,
      ...componentsConfigs,
      ...userPagesConfig,
      ...userListConfig,
      ...invoiceConfig,
      ...customRoutes,
    ],
  };
};

export const unAuthorizedStructure = (initialUrl) => {
  return {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
  };
};

export const anonymousStructure = (initialUrl) => {
  return {
    routes: errorPagesConfigs.concat([
      {
        path: '/',
        element: <Navigate to={initialUrl} />,
      },
      {
        path: '*',
        element: <Navigate to='/error-pages/error-404' />,
      },
    ]),
  };
};
