import React from 'react';
import {
  BiBook,
  BiCalendar,
  BiDollar,
  BiPhoneIncoming,
  BiPhoneOutgoing,
  BiBriefcase,
  BiUser,
  BiGroup,
  BiHelpCircle,
  BiPhoneCall,
  BiBookOpen,
  BiListUl,
  BiLayer,
} from 'react-icons/bi';
import {MdSpaceDashboard} from 'react-icons/md';

const routesConfig = [
  {
    id: 'app',
    title: 'App Menu',
    messageId: 'sidebar.menu',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.dashboard',
        type: 'item',
        icon: <BiLayer />,
        path: '/dashboard',
      },
      {
        id: 'page-1',
        title: 'Page 1',
        messageId: 'sidebar.page.availableDates',
        type: 'item',
        icon: <BiCalendar />,
        path: '/demo/available-date',
      },
      {
        id: 'page-2',
        title: 'Page 2',
        messageId: 'sidebar.page.availableDatesPremium',
        type: 'item',
        icon: <BiCalendar />,
        path: '/demo/available-date-premium',
      },
      {
        id: 'enquiry',
        title: 'Enquiry',
        messageId: 'sidebar.page.enquiry',
        type: 'collapse',
        icon: <BiPhoneIncoming />,
        children: [
          {
            id: 'page-3',
            title: 'Page 3',
            messageId: 'sidebar.page.enquiry',
            type: 'item',
            icon: <BiPhoneIncoming />,
            path: '/sales/enquiry',
          },
          {
            id: 'page-4',
            title: 'Page 4',
            messageId: 'sidebar.page.viewAllEnquiry',
            type: 'item',
            icon: <BiListUl />,
            path: '/sales/view-all-enquiry',
          },
        ],
      },
      {
        id: 'customEnquiry',
        title: 'Custom Enquiry',
        messageId: 'sidebar.page.customizedEnquiry',
        type: 'collapse',
        icon: <BiPhoneCall />,
        children: [
          {
            id: 'page-5',
            title: 'Page 5',
            messageId: 'sidebar.page.customizedEnquiry',
            type: 'item',
            icon: <BiPhoneCall />,
            path: '/sales/customized-enquiry',
          },
          {
            id: 'page-6',
            title: 'Page 6',
            messageId: 'sidebar.page.viewAllCustomEnquiry',
            type: 'item',
            icon: <BiListUl />,
            path: '/sales/view-all-custom-enquiry',
          },
        ],
      },
      {
        id: 'follow-up-1',
        title: 'follow-up 1',
        messageId: 'sidebar.page.followUp',
        type: 'item',
        icon: <BiPhoneOutgoing />,
        path: '/sales/follow-up',
      },
      {
        id: 'Booking',
        title: 'Booking',
        messageId: 'sidebar.page.booking',
        type: 'collapse',
        icon: <BiBook />,
        children: [
          {
            id: 'page-7',
            title: 'Page 7',
            messageId: 'sidebar.page.booking',
            type: 'item',
            icon: <BiBook />,
            path: '/sales/booking',
          },
          {
            id: 'page-8',
            title: 'Page 8',
            messageId: 'sidebar.page.viewAllBooking',
            type: 'item',
            icon: <BiListUl />,
            path: '/sales/view-all-booking',
          },
        ],
      },
      {
        id: 'CustomBooking',
        title: 'Custom Booking',
        messageId: 'sidebar.page.customizedBooking',
        type: 'collapse',
        icon: <BiBookOpen />,
        children: [
          {
            id: 'page-9',
            title: 'Page 9',
            messageId: 'sidebar.page.customizedBooking',
            type: 'item',
            icon: <BiBookOpen />,
            path: '/sales/customized-booking',
          },
          {
            id: 'page-10',
            title: 'Page 10',
            messageId: 'sidebar.page.viewAllCustomBooking',
            type: 'item',
            icon: <BiListUl />,
            path: '/sales/view-all-custom-booking',
          },
        ],
      },
      {
        id: 'payments',
        title: 'Payments',
        messageId: 'sidebar.page.payments',
        type: 'collapse',
        icon: <BiDollar />,
        children: [
          {
            id: 'page-11a',
            title: 'Page 11a',
            messageId: 'sidebar.page.payments',
            type: 'item',
            icon: <BiDollar />,
            path: '/sales/payment',
          },
          {
            id: 'page-11b',
            title: 'Page 11b',
            messageId: 'sidebar.page.all_received_payment',
            type: 'item',
            icon: <BiListUl />,
            path: '/sales/view-all-received-payment',
          },
        ],
      },
      {
        id: 'page-12',
        title: 'Page 12',
        messageId: 'sidebar.page.todaysWork',
        type: 'item',
        icon: <BiBriefcase />,
        path: '/demo/todays-work',
      },
      // {
      //   id: 'page-13',
      //   title: 'Page 13',
      //   messageId: 'sidebar.page.clientsList',
      //   type: 'item',
      //   icon: <BiGroup />,
      //   path: '/demo/clients-list',
      // },
      {
        id: 'page-14',
        title: 'Page 14',
        messageId: 'sidebar.page.selfAccount',
        type: 'item',
        icon: <BiUser />,
        path: '/demo/self-account',
      },

      // {
      //   id: 'page-15',
      //   title: 'Page 15',
      //   messageId: 'sidebar.page.help',
      //   type: 'item',
      //   icon: <BiHelpCircle />,
      //   path: '/demo/help',
      // },
      //   {
      //     id: 'page-14',
      //     title: 'Page 14',
      //     messageId: 'sidebar.page.page1',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-1',
      //   },
      //   {
      //     id: 'page-15',
      //     title: 'Page 15',
      //     messageId: 'sidebar.page.page2',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-2',
      //   },
      //   {
      //     id: 'page-16',
      //     title: 'Page 16',
      //     messageId: 'sidebar.page.page3',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-3',
      //   },
      //   {
      //     id: 'page-17',
      //     title: 'Page 17',
      //     messageId: 'sidebar.page.page4',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-4',
      //   },
      //   {
      //     id: 'page-18',
      //     title: 'Page 18',
      //     messageId: 'sidebar.page.page5',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-5',
      //   },
      //   {
      //     id: 'page-19',
      //     title: 'Page 19',
      //     messageId: 'sidebar.page.page6',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-6',
      //   },
      //   {
      //     id: 'page-20',
      //     title: 'Page 20',
      //     messageId: 'sidebar.page.page7',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-7',
      //   },
      //   {
      //     id: 'page-21',
      //     title: 'Page 21',
      //     messageId: 'sidebar.page.page8',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-8',
      //   },
      //   {
      //     id: 'page-22',
      //     title: 'Page 22',
      //     messageId: 'sidebar.page.page9',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-9',
      //   },
      //   {
      //     id: 'page-23',
      //     title: 'Page 23',
      //     messageId: 'sidebar.page.page10',
      //     type: 'item',
      //     icon: <BiHelpCircle />,
      //     path: '/demo/page-10',
      //   },
    ],
  },
];
export default routesConfig;
