import React from 'react';
import PropTypes from 'prop-types';

import {useIntl} from 'react-intl';
import AppRowContainer from '@crema/components/AppRowContainer';
import {Avatar, Col} from 'antd';
import AppList from '@crema/components/AppList';
import {
  StyledSalesStateCard,
  StyledSalesStateContent,
  StyledSalesStateItem,
  StyledSalesStateItemContent,
  StyledSalesStateMain,
} from './index.styled';
import './index.scss';

const SPMonthlyStatsCard = ({salesPersonsStat}) => {
  const handleSelectionType = (data) => {
    console.log('data: ', data);
  };
  const {messages} = useIntl();
  return (
    console.log('salesPersonsStat: ', salesPersonsStat),
    (
      <StyledSalesStateCard title={'Monthly Enquiries/ Booking'} heightFull>
        <div className='monthly_card_wrapper'>
          <div className='card_info'>
            {Array.isArray(salesPersonsStat.enquiry_stats) &&
              salesPersonsStat.enquiry_stats.map((enquiryStat) => {
                const bookingStat = salesPersonsStat.booking_stats.find(
                  (stat) => stat.user_id === enquiryStat.user_id,
                );
                return (
                  <div className='item' key={enquiryStat.user_id}>
                    <div className='user_name'>{enquiryStat.name}</div>
                    <div className='btn_booking_or_enquiry_count'>
                      {enquiryStat?.total_enquiry_count
                        ? enquiryStat?.total_enquiry_count
                        : 0}
                      /{bookingStat ? bookingStat.total_booking_count : 0}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </StyledSalesStateCard>
    )
  );
};
export default SPMonthlyStatsCard;

SPMonthlyStatsCard.defaultProps = {
  salesPersonsStat: [],
};

SPMonthlyStatsCard.propTypes = {
  salesPersonsStat: PropTypes.array,
};
