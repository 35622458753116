import React from 'react';
import {
  BiBook,
  BiCalendar,
  BiDollar,
  BiPhoneIncoming,
  BiBriefcase,
  BiUser,
  BiGroup,
  BiHelpCircle,
  BiPhoneCall,
  BiBookOpen,
  BiListUl,
  BiLayer,
} from 'react-icons/bi';
import {MdSpaceDashboard} from 'react-icons/md';

const routeConfigVisaAgent = [
  {
    id: 'app',
    title: 'Visa Executive Menu',
    messageId: 'sidebar.visaExecutive',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.dashboard',
        type: 'item',
        icon: <BiLayer />,
        path: '/visa-agent/dashboard',
      },
    ],
  },
];
export default routeConfigVisaAgent;
