import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const PageDashboard = React.lazy(() =>
  import('../../../modules/adminPages/dashboard'),
);

const PageAddDate = React.lazy(() =>
  import('../../../modules/adminPages/add_date'),
);

const PageViewAllEnquiry = React.lazy(() =>
  import('../../../modules/adminPages/view_all_enquiry'),
);
const PageEditEnquiry = React.lazy(() =>
  import('../../../modules/adminPages/page_enquiry/edit_enquiry'),
);

const PageViewAllCustomEnquiry = React.lazy(() =>
  import('../../../modules/adminPages/view_all_custom_enquiry'),
);
const PageEditCustomEnquiry = React.lazy(() =>
  import('../../../modules/adminPages/page_custom_enquiry/edit_enquiry'),
);

const PageViewAllFollowUp = React.lazy(() =>
  import('../../../modules/adminPages/page_view_all_follow_up'),
);

const PageViewAllBooking = React.lazy(() =>
  import('../../../modules/adminPages/view_all_booking'),
);
const PageEditBooking = React.lazy(() =>
  import('../../../modules/adminPages/page_booking/edit_booking'),
);

const PageViewAllCustomBooking = React.lazy(() =>
  import('../../../modules/adminPages/view_all_custom_booking'),
);
const PageEditCustomBooking = React.lazy(() =>
  import('../../../modules/adminPages/page_custom_booking/edit_booking'),
);

const PageViewAllBookingSchedule = React.lazy(() =>
  import('../../../modules/adminPages/view_all_booking_schedule'),
);

const PageViewAllReceivedPayment = React.lazy(() =>
  import('../../../modules/adminPages/page_view_all_received_payment'),
);
const PageManageNavigation = React.lazy(() =>
  import('../../../modules/adminPages/manage_navigation'),
);
const PageManagePackageType = React.lazy(() =>
  import('../../../modules/adminPages/manage_package_type'),
);
const PageManagePackage = React.lazy(() =>
  import('../../../modules/adminPages/manage_package'),
);
const PageCreatePackage = React.lazy(() =>
  import('../../../modules/adminPages/manage_package/create_package.js'),
);
const PageEditPackage = React.lazy(() =>
  import('../../../modules/adminPages/manage_package/edit_package.js'),
);
const PageCommunicationSourceCategory = React.lazy(() =>
  import('../../../modules/adminPages/manage_communication_source_category'),
);
const PageReferenceSourceCategory = React.lazy(() =>
  import('../../../modules/adminPages/manage_reference_source_category'),
);
const PageManageAirport = React.lazy(() =>
  import('../../../modules/adminPages/manage_airport'),
);
const PageFlightClass = React.lazy(() =>
  import('../../../modules/adminPages/manage_flight_class'),
);

const PageAirlines = React.lazy(() =>
  import('../../../modules/adminPages/manage_airlines'),
);
const PageVehicleType = React.lazy(() =>
  import('../../../modules/adminPages/manage_vehicle_type'),
);
const PageManageUsers = React.lazy(() =>
  import('../../../modules/adminPages/manage_users'),
);
export const adminSidebarPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/dashboard',
    element: <PageDashboard />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/add-date',
    element: <PageAddDate />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/view-all-enquiry',
    element: <PageViewAllEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/edit-enquiry/:enquiryId',
    element: <PageEditEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/view-all-custom-enquiry',
    element: <PageViewAllCustomEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/edit-custom-enquiry/:enquiryId',
    element: <PageEditCustomEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/follow-up',
    element: <PageViewAllFollowUp />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/view-all-booking',
    element: <PageViewAllBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/edit-booking/:bookingId',
    element: <PageEditBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/view-all-custom-booking',
    element: <PageViewAllCustomBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/edit-custom-booking/:bookingId',
    element: <PageEditCustomBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/view-all-booking-schedule',
    element: <PageViewAllBookingSchedule />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/view-all-received-payment',
    element: <PageViewAllReceivedPayment />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage-navigation',
    element: <PageManageNavigation />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/package-type',
    element: <PageManagePackageType />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/manage-package',
    element: <PageManagePackage />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/create-package',
    element: <PageCreatePackage />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/edit-package/:packageId',
    element: <PageEditPackage />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/communication-source-category',
    element: <PageCommunicationSourceCategory />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/reference-source-category',
    element: <PageReferenceSourceCategory />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/manage-airport',
    element: <PageManageAirport />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/manage-flight-class',
    element: <PageFlightClass />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/manage-airlines',
    element: <PageAirlines />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/manage-vehicle-type',
    element: <PageVehicleType />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/manage/manage-users',
    element: <PageManageUsers />,
  },
];
