import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const PageAvailableDate = React.lazy(() =>
  import('../../../modules/demo/page_available_date'),
);
const PageAvailableDatePremium = React.lazy(() =>
  import('../../../modules/demo/page_available_date_premium'),
);
const PageEnquiry = React.lazy(() =>
  import('../../../modules/salesPages/page_enquiry'),
);
const PageViewAllEnquiry = React.lazy(() =>
  import('../../../modules/salesPages/page_view_all_enquries'),
);
const PageCustomizedEnquiry = React.lazy(() =>
  import('../../../modules/salesPages/page_customized_enquiry'),
);
const PageViewAllCustomEnquiry = React.lazy(() =>
  import('../../../modules/salesPages/page_view_all_custom_enquiries'),
);
const PageViewAllFollowUp = React.lazy(() =>
  import('../../../modules/salesPages/page_view_all_follow_up'),
);
const PageBooking = React.lazy(() =>
  import('../../../modules/salesPages/page_booking'),
);
const PageViewAllBooking = React.lazy(() =>
  import('../../../modules/salesPages/page_view_all_booking'),
);
const PageCustomizedBooking = React.lazy(() =>
  import('../../../modules/salesPages/page_customized_booking'),
);
const PageViewAllCustomBooking = React.lazy(() =>
  import('../../../modules/salesPages/page_view_all_custom_booking'),
);
const PageViewAllReceivedPayment = React.lazy(() =>
  import('../../../modules/salesPages/page_view_all_received_payment'),
);
const PageTodaysWork = React.lazy(() =>
  import('../../../modules/demo/page_todays_work'),
);
// const PageClientList = React.lazy(() =>
//   import('../../../modules/demo/page_client_list'),
// );
const PageSelfAccount = React.lazy(() =>
  import('../../../modules/demo/page_self_account'),
);
const PageHelp = React.lazy(() => import('../../../modules/demo/page_help'));
const PagePayment = React.lazy(() =>
  import('../../../modules/salesPages/page_payment'),
);

const Page1 = React.lazy(() => import('../../../modules/demo/page_1'));
const Page2 = React.lazy(() => import('../../../modules/demo/page_2'));
const Page3 = React.lazy(() => import('../../../modules/demo/page_3'));
const Page4 = React.lazy(() => import('../../../modules/demo/page_4'));
const Page5 = React.lazy(() => import('../../../modules/demo/page_5'));
const Page6 = React.lazy(() => import('../../../modules/demo/page_6'));
const Page7 = React.lazy(() => import('../../../modules/demo/page_7'));
const Page8 = React.lazy(() => import('../../../modules/demo/page_8'));
const Page9 = React.lazy(() => import('../../../modules/demo/page_9'));
const Page10 = React.lazy(() => import('../../../modules/demo/page_10'));

export const sidebarPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/available-date',
    element: <PageAvailableDate />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/available-date-premium',
    element: <PageAvailableDatePremium />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/enquiry',
    element: <PageEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/view-all-enquiry',
    element: <PageViewAllEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/customized-enquiry',
    element: <PageCustomizedEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/view-all-custom-enquiry',
    element: <PageViewAllCustomEnquiry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/follow-up',
    element: <PageViewAllFollowUp />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/booking',
    element: <PageBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/view-all-booking',
    element: <PageViewAllBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/customized-booking',
    element: <PageCustomizedBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/view-all-custom-booking',
    element: <PageViewAllCustomBooking />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/view-all-received-payment',
    element: <PageViewAllReceivedPayment />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/todays-work',
    element: <PageTodaysWork />,
  },
  // {
  //   permittedRole: RoutePermittedRole.User,
  //   path: '/demo/clients-list',
  //   element: <PageClientList />,
  // },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/self-account',
    element: <PageSelfAccount />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/help',
    element: <PageHelp />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sales/payment',
    element: <PagePayment />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-1',
    element: <Page1 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-2',
    element: <Page2 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-3',
    element: <Page3 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-4',
    element: <Page4 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-5',
    element: <Page5 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-6',
    element: <Page6 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-7',
    element: <Page7 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-8',
    element: <Page8 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-9',
    element: <Page9 />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/demo/page-10',
    element: <Page10 />,
  },
];
