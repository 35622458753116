import React from 'react';
import PropTypes from 'prop-types';
import StatGraphs from './StatGraphs';
import {useIntl} from 'react-intl';
import AppSelect from '@crema/components/AppSelect';
import {StyledVisitorAction, StyledVisitorCard} from './index.styled';

const SalesEnquiryBookingChart = ({data}) => {
  const handleSelectionType = (data) => {
    console.log('data: ', data);
  };
  const {messages} = useIntl();

  return (
    <StyledVisitorCard
      title='Your Booking and Enquirers'
      extra={
        <StyledVisitorAction>
          <div className='visitor-action-view'>
            <div className='visitor-action-item'>
              <span
                className='dot-visitor'
                style={{backgroundColor: '#0A8FDC'}}
              />
              Booking
            </div>
            <div className='visitor-action-item'>
              <span
                className='dot-visitor'
                style={{backgroundColor: '#F04F47'}}
              />
              Enquiries
            </div>
          </div>
          {/* <AppSelect
            menus={[
              messages['dashboard.thisWeek'],
              messages['dashboard.lastWeeks'],
              messages['dashboard.lastMonth'],
            ]}
            defaultValue={messages['dashboard.thisWeek']}
            onChange={handleSelectionType}
          /> */}
        </StyledVisitorAction>
      }
    >
      <StatGraphs data={data} />
    </StyledVisitorCard>
  );
};
export default SalesEnquiryBookingChart;

SalesEnquiryBookingChart.defaultProps = {
  data: [],
};

SalesEnquiryBookingChart.propTypes = {
  data: PropTypes.array,
};
