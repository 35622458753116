import React from 'react';
import PropTypes from 'prop-types';

import {useIntl} from 'react-intl';
import AppRowContainer from '@crema/components/AppRowContainer';
import {Avatar, Col} from 'antd';
import AppList from '@crema/components/AppList';
import {
  StyledSalesStateCard,
  StyledSalesStateContent,
  StyledSalesStateItem,
  StyledSalesStateItemContent,
  StyledSalesStateMain,
} from './index.styled';
import './index.scss';

const SalesTodaysPaymentCard = ({todaysPayment}) => {
  const handleSelectionType = (data) => {
    console.log('data: ', data);
  };
  const {messages} = useIntl();
  return (
    <StyledSalesStateCard title={"Today's Payment"} heightFull>
      <div className='payment_card_wrapper' style={{marginTop: '20px'}}>
        <div className='card_header'>
          <div className='total'>Total : {todaysPayment.total_amount}</div>
        </div>
        <div className='card_info'>
          <div className='left'>
            <div className='payment_item'>
              <div className='payment_value'>{todaysPayment.cash_amount}</div>
              <div className='payment_type'>Cash</div>
            </div>
            <div className='payment_item'>
              <div className='payment_value'>{todaysPayment.cheque_amount}</div>
              <div className='payment_type'>Cheque</div>
            </div>
          </div>
          <div className='right'>
            <div className='payment_item'>
              <div className='payment_value'>
                {todaysPayment.bank_transfer_amount}
              </div>
              <div className='payment_type'>Bank Transfer</div>
            </div>
            <div className='payment_item'>
              <div className='payment_value'>{todaysPayment.upi_amount}</div>
              <div className='payment_type'>UPI</div>
            </div>
          </div>
        </div>
      </div>
    </StyledSalesStateCard>
  );
};
export default SalesTodaysPaymentCard;

SalesTodaysPaymentCard.defaultProps = {
  todaysPayment: [],
};

SalesTodaysPaymentCard.propTypes = {
  todaysPayment: PropTypes.array,
};
